import {
  PAYMENT_METHOD_TOKENIZED_CREDIT_CARD,
  PAYMENT_METHOD_CREDIT_CARD,
  PAYMENT_METHOD_INVOICE_REQUEST,
  PAYMENT_TYPE_INVOICE,
  PAYMENT_TYPE_CARD,
} from '@oracle-cx-commerce/commerce-utils/constants';

export const CARDS_MAPPING = {
  visa: 'Visa',
  amex: 'AmericanExpress',
  mastercard: 'MasterCard',
};

export const HARDCODED_VALUES = {
  msiBillingContacts: '[]',
  msiIsFallback: false,
  msiShipComplete: false,
  msiMakeBillToDefault: true,
  msiMakeShipToDefault: true,
  msiGroupStandaloneOptions: true,
  msiRegion: 'NA',
};

export const PAYMENT_TYPE_MAPPING = {
  [PAYMENT_METHOD_CREDIT_CARD]: PAYMENT_TYPE_CARD,
  [PAYMENT_METHOD_TOKENIZED_CREDIT_CARD]: PAYMENT_TYPE_CARD,
  [PAYMENT_METHOD_INVOICE_REQUEST]: PAYMENT_TYPE_INVOICE,
};
